import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getEmployee } from './employees.slice';
import { useParams } from 'react-router-dom';
import EmployeeHeaderSection from './Sections/EmployeeHeaderSection';
import SectionWrapper from '../SectionWrapper';
import EmployeeDetailsSection from './Sections/EmployeeDetailsSection';
import EmployeesSitesSection from './Sections/EmployeesSitesSection';

export default function EmployeeView() {
    const employee = useSelector(state=> state.employees.employee);
    const dispatch = useDispatch();
    const {id} = useParams();

    useEffect(()=>{
        dispatch(getEmployee(id))
    },[id])

  return (
    <div>
        <EmployeeHeaderSection employee={employee}/>
        <SectionWrapper title={"פרטים"}>
               <EmployeeDetailsSection employee={employee}/>
                </SectionWrapper>
        <SectionWrapper title={"אתרים משוייכים"}>
               <EmployeesSitesSection employee={employee}/>
                </SectionWrapper>
    </div>
  )
}
