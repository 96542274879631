import React from 'react';
import '../../globals.css'
import SectionWrapper from "../SectionWrapper";
import {CalendarIcon} from '@heroicons/react/20/solid'
import {currencyFormatter, formatDate} from "../Site/site.utils";

export default function SingleExpense({expense}) {
    const renderExpenseTitle = () => {
        const formattedExpense = expense.amount.toLocaleString(undefined, currencyFormatter);
        return (
            <div className="lg:flex lg:items-center lg:justify-between">
                <div className="min-w-0 flex-1">
                        <strong className="block font-medium text-gray-400">{expense.title}</strong>
                    <div className="mt-1 justify-between flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                        <div className="mt-2 flex items-center text-sm text-gray-500">
                            <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            {formatDate(expense.createdDate)}
                        </div>
                        <div className="mt-2 flex items-center text-sm text-gray-500">

                        </div>
                        <div className="mt-2 flex items-center text-sm text-gray-500 hidden sm:block">

                        </div>
                        <div className="mt-2 flex items-center text-sm text-gray-500">
                            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                                {formattedExpense}
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const renderExpenseBody = () => {
        return (
            <div className="flow-root">
                <dl className="-my-3 divide-y divide-gray-100 text-sm">
                    <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
                        <dt className="font-medium text-gray-900">עבור: </dt>
                        <dd className="text-gray-700 sm:col-span-3">{expense.paidTo}</dd>
                    </div>
                    <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
                        <dt className="font-medium text-gray-900">סוג תשלום: </dt>
                        <dd className="text-gray-700 sm:col-span-3">{expense.paymentType}</dd>
                    </div>
                    <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
                        <dt className="font-medium text-gray-900">מספר חשבונית: </dt>
                        <dd className="text-gray-700 sm:col-span-3">{expense.receiptNumber}</dd>
                    </div>
                    <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
                        <dt className="font-medium text-gray-900">פרטים נוספים: </dt>
                        <dd className="text-gray-700 sm:col-span-3">{expense.details}</dd>
                    </div>
                    <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
                        <dt className="font-medium text-gray-900">תאריך תשלום: </dt>
                        <dd className="text-gray-700 sm:col-span-3">{formatDate(expense.paymentDate)}</dd>
                    </div>
                </dl>
            </div>
        )
    }
    return (
        <SectionWrapper key={expense.id} title={renderExpenseTitle()}>
            {renderExpenseBody()}
        </SectionWrapper>
    );
}
