import React from 'react'
import {currencyFormatter} from "../Site/site.utils";

export default function BudgetSection({budget, paid, expenses}) {
    const formattedBudget = budget.toLocaleString(undefined, currencyFormatter);
    const formattedPaid = paid.toLocaleString(undefined, currencyFormatter);

    const totalExpenses = expenses && expenses.reduce((accumulator, item) => {
        return accumulator + item.amount;
    }, 0);

    const formattedExpenses = totalExpenses ? totalExpenses.toLocaleString(undefined,currencyFormatter): "₪0";

    return (
        <section>
            <article
                className="flex flex-col gap-4 rounded-lg border border-gray-100 bg-white p-6"
            >
                <div
                    className="inline-flex gap-2 self-end rounded bg-green-100 p-1 text-green-600"
                >
                    <span className="text-xs font-medium"> {budget !=0 && paid != 0 && ((paid/budget)*100).toFixed(2)}% </span>
                </div>

                <div>
                    <strong className="block text-sm font-medium text-gray-500"> תקציב </strong>

                    <p>
                        <span className="text-2xl font-medium text-gray-900"> {formattedBudget} </span>

                        <span className="text-xs text-gray-500"> שולם {formattedPaid} </span>
                    </p>
                    <p className="block text-sm font-medium text-gray-500">הוצאות:</p>
                    <p>
                        <span className="text-2xl font-medium text-gray-900"> {formattedExpenses} </span>
</p>
                </div>
            </article>
        </section>
    )
}
