import React, { useState } from 'react';
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import '../../globals.css'
import { ContactForm } from "../Contact/ContactForm";
import {createOrUpdateSite, getApplicationData} from "./sites.slice";
import { useNavigate } from "react-router-dom";
import SiteType from "./SiteType";


export function CreateSiteForm() {
    const siteDefaults = useSelector((state) => state.sites.newSiteDefaults);
    const { register, handleSubmit } = useForm()
    const dispatch = useDispatch();
    const applicationData = useSelector((state) => state.sites.applicationData);
    if (!applicationData){
        dispatch(getApplicationData());
    }
    const [type, setType] = useState();
    const [contact, setContact] = useState(siteDefaults.contacts[0]);
    const nav = useNavigate()
    const updateSiteContact = (contact) => {
        setContact(contact);
    }
    const handleCreateSite = async (data) => {
        if(type != null){
            data.type = {
                "id": type.id,
                "typeName": type.typeName,
            };
        }
        data.contacts = [contact];
        await dispatch(createOrUpdateSite(data));
        nav("/");
    };

    const renderSiteTypes = () => {
        if (applicationData && applicationData.configurations && applicationData.configurations.siteTypes){
            return applicationData.configurations.siteTypes.map((type,i) => (
                <SiteType type={type} key={i} onClick={(type)=> setType(type)}/>
            ));
        }
    }
    //todo: 1. add x to the "create site form" to return to the site's grid
    return (
        <>
         <div className='flex justify-end my-2'>
                <button onClick={()=>nav(-1)}>הקודם</button>
                </div>
        <form onSubmit={handleSubmit(handleCreateSite)} className="space-y-4">
            <div className='h3 text-center'>יצירת אתר חדש:</div>
            <div>
                <input
                    className="w-full rounded-lg border-gray-200 p-3 text-sm border"
                    type="text"
                    id="name"
                    placeholder="שם האתר *"
                    required
                    {...register('name')}
                    />
            </div>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div>
                    <input
                        className="w-full rounded-lg border-gray-200 p-3 text-sm border"
                        type="text"
                        id="city"
                        placeholder="עיר *"
                        required
                        {...register('city')}
                    />
                </div>
                <div>
                    <input
                        className="w-full rounded-lg border-gray-200 p-3 text-sm border"
                        type="text"
                        id="address"
                        placeholder="כתובת (רחוב ומספר) *"
                        required
                        {...register('address')}
                    />
                </div>
            </div>
            {/* {applicationData && applicationData.configurations && applicationData.configurations.siteTypes.length > 0 &&
            <>
                <label>*</label>
            <div className="grid grid-cols-3 gap-4 text-center ">
                {renderSiteTypes()}
            </div>
            </>
            } */}

            <div>
            <ContactForm register={register} contact={contact} setContact={setContact}></ContactForm>
            </div>
            <div className="mt-4 text-center">
                <button
                    type="submit"
                    className="inline-block w-full rounded-full bg-gray-600 px-5 py-3 font-medium text-white sm:w-auto hover:bg-gray-900"
                >
                    הוספת אתר
                </button>
            </div>
        </form>
        </>

    );
}
