import React, {useState} from 'react';
import '../../globals.css'
import Popup from "../Common/Popup";
import SingleContact from "./SingleContact";
import {useDispatch, useSelector} from "react-redux";
import {ContactForm} from "../Contact/ContactForm";
import {addContact} from "../Site/sites.slice";
import { useForm } from 'react-hook-form';

export default function ContactSection({contacts}) {
    const [isOpen, setIsOpen] = useState(false);
    const site = useSelector((state) => state.sites.site);
    const dispatch = useDispatch();
    const [contact,setContact] = useState({});
    const { register, handleSubmit,reset } = useForm()


    const handleAddContact = async (data) => {
        console.log(contact);
        data.siteId = site.id;
        console.log(data);
        await dispatch(addContact(data));
        reset();
        setIsOpen(false);
    };
    const renderContacts = () => {
        if (contacts) {
            return contacts.map((contact) => (<SingleContact key={contact.id} contact={contact}></SingleContact>));
        }
    }
    return (
        <section>
            <div>
                {renderContacts()}
            </div>
            <div>
                <a
                    className="float-left mt-3 mb-3 inline-block rounded border border-indigo-600 px-12 py-3 text-sm font-medium text-indigo-600 hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring active:bg-indigo-500"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    הוספת איש קשר
                </a>
                {isOpen && <div className="rounded-lg border border-gray-100 text-center shadow-xl">
                    <Popup title={"הוספת איש קשר"} cancelButtonTitle={"ביטול"} saveButtonTitle={"שמירה"}
                        onCancel={() => setIsOpen(false)}>
                            <form onSubmit={handleSubmit(handleAddContact)}>
                        <ContactForm register={register} contact={contact} setContact={setContact}/>
                        <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 flex justify-center mt-4">
                    <button
                        type="submit"
                        className="me-2 inline-block rounded border border-indigo-600 px-12 py-3 text-sm font-medium text-indigo-600 hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring active:bg-indigo-500"
                    >
                         שמירה
                    </button>
                    <button
                        type="button"
                        className="ms-2 inline-block rounded border border-indigo-600 px-12 py-3 text-sm font-medium text-indigo-600 hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring active:bg-indigo-500"
                        onClick={() => setIsOpen(false)}
                    >
                        ביטול
                    </button>
                </div>
                            </form>
                    </Popup>
                </div>}
            </div>
        </section>
    );
}
