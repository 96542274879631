import React from 'react'
import {useForm} from "react-hook-form"
import { useDispatch, useSelector } from 'react-redux';
import {useNavigate} from 'react-router-dom';

import { createOrUpdateEmployee } from './employees.slice';
import InputWithLabel from '../Common/InputWithLabel';

export default function NewEmployeeForm({associateNewEmployee = ()=>{}}) {
    const{register , handleSubmit } = useForm();
    const employees = useSelector(state=> state.employees.data);
    const dispatch = useDispatch();
    const nav = useNavigate();
    const form_fields = [
        {title:"שם פרטי*", id:"FirstName",type:"text",required:true},
        {title:"שם משפחה*", id:"LastName",type:"text",required:true},
        {title:"טלפון", id:"PhoneNumber",type:"phone",required:false},
        {title:"אימייל", id:"Email",type:"email",required:false},
        {title:"עיר", id:"City",type:"text",required:false},
        {title:"כתובת", id:"Address",type:"text",required:false},
        {title:"תפקיד*", id:"EmployeeType",type:"text",required:true},
        {title:"מחיר לשעה", id:"UnitCost",type:"number",required:false},
];

    const onSubmit =async (data)=>{
        if(data.UnitCost == ""){
            data.UnitCost = null;
        }
    await dispatch(createOrUpdateEmployee(data));
    if(associateNewEmployee.toString() === "() => {}"){
        nav("/employees")
    }else{
        associateNewEmployee();
    }
    }

  return (
    <div className='flex flex-col justify-center'>
        <h2 className='text-center h2 my-4'>טופס הוספת עובד:</h2>
    <form onSubmit={handleSubmit(onSubmit)} className='w-10/12 mx-auto'>
        <div className='grid grid-cols-2 gap-3 w-50'>
    {form_fields.map((item,i)=>{
return(
    <InputWithLabel key={i} id={item.id} title={item.title} type={item.type} register={register} required={item.required}/>
)
})
}
</div>
<div className='flex justify-center'>
    <button className='rounded-full bg-gray-600 text-white py-2 px-3 mt-5'>הוספה</button>
</div>
    </form>
    </div>
    )
}
