import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function SiteGridItem({item}) {
    const nav = useNavigate();
  return (
    <div onClick={() => {
        nav("/sites/" + item.id);
    }} className='border p-4 rounded-lg text-center drop-shadow-md'>
        <div className='h4'>{item.name}</div>
        <p>{item.address}, {item.city}</p>
        </div>
  )
}
