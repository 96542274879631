import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import '../../globals.css'
import Popup from "../Common/Popup";
import SinglePayment from "./SinglePayment";
import InputWithLabel from "../Common/InputWithLabel";
import {addPayment} from "../Site/sites.slice";

export default function PaymentsSection({payments}) {
    const { register, handleSubmit, reset } = useForm()
    const [isOpen, setIsOpen] = useState(false);
    const site = useSelector((state) => state.sites.site);
    const dispatch = useDispatch();
    const handleCreatePayment = async (data) => {
        data.siteId = site.id;
        data.actualDayOfPayment = undefined;
        console.log(data);
        await dispatch(addPayment(data));
        reset();
        setIsOpen(false);
    };
    const renderPayments = () => {
        if (payments) {
            return payments.map((payment) => (<SinglePayment key={payment.id} payment={payment}></SinglePayment>));
        }
    }
    return (<section>
        <div>
            {renderPayments()}
        </div>
        <div>
            <a
                className="float-left mt-3 mb-3 inline-block rounded border border-indigo-600 px-12 py-3 text-sm font-medium text-indigo-600 hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring active:bg-indigo-500"
                onClick={() => setIsOpen(!isOpen)}
            >
                הוסף תשלום
            </a>
            {isOpen && <div className="rounded-lg border border-gray-100 text-center shadow-xl">
                <Popup title={"הוספת תשלום"} cancelButtonTitle={"ביטול"} saveButtonTitle={"שמירה"}
                       onCancel={() => setIsOpen(false)} >
                    <form onSubmit={handleSubmit(handleCreatePayment)}>
                        <div className="space-y-12">
                            <div className="mt-6 grid grid-cols-1 gap-x-10 gap-y-10 sm:grid-cols-6">
                                    <div className="sm:col-span-12">
                                    <InputWithLabel type={"text"} id={"from"} title={"מאת: "} required={true} register={register}/>
                                </div>
                            </div>
                            <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                    <InputWithLabel id={"amount"} type={"text"} title={"סכום לתשלום (לפני מע״מ):"} register={register}/>
                                </div>
                                <div className="sm:col-span-3">
                                    <InputWithLabel id={"amount-with-tax"} type={"text"} readOnly
                                                    title={"סכום לתשלום (כולל מע״מ):"} value={"1"}/>
                                </div>
                                <div className="sm:col-span-3">
                                    <InputWithLabel id={"paymentType"} type={"text"} title={"סוג תשלום:"}  register={register}/>
                                </div>
                                <div className="sm:col-span-3">
                                    <InputWithLabel id={"actualDayOfPayment"} type={"text"}
                                                    title={"מועד התשלום (אופציונלי):"} register={register}/>
                                </div>
                            </div>
                            <div className="mt-4 grid grid-cols-1 gap-x-10 gap-y-10 sm:grid-cols-6">
                                <div className="sm:col-span-12">
                                    <InputWithLabel id={"receiptNumber"} type={"text"} title={"מספר חשבונית/קבלה:"}  register={register}/>
                                </div>
                            </div>
                            <div className="mt-4 col-span-full">
                                <InputWithLabel id={"details"} type={"textarea"} title={"פרטים נוספים:"} register={register}/>
                            </div>
                        </div>
                        <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 flex justify-center mt-4">
                    <button
                        type="submit"
                        className="me-2 inline-block rounded border border-indigo-600 px-12 py-3 text-sm font-medium text-indigo-600 hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring active:bg-indigo-500"
                    >
                         שמירה
                    </button>
                    <button
                        type="button"
                        className="ms-2 inline-block rounded border border-indigo-600 px-12 py-3 text-sm font-medium text-indigo-600 hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring active:bg-indigo-500"
                        onClick={() => setIsOpen(false)}
                    >
                        ביטול
                    </button>
                </div>
                    </form>
                </Popup>
            </div>}
        </div>
    </section>);
}
