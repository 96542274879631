import {axiosInstance} from '../services/commonConfiguration';

export async function registerUserApi(user) {
    return await axiosInstance.post('/auth/register', user);
}
export async function loginUserApi(user) {
    return await axiosInstance.post('/auth/login', user);
}
export async function logoutUserApi() {
    return await axiosInstance.post('/auth/logout');
}
export async function getUserApi() {
    return await axiosInstance.get('/user');
}
export async function getSitesApi(){
    return await axiosInstance.get('/site')
}
