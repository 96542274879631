import React from 'react';
import { SiteRow } from "./SiteRow";
import '../../globals.css'
import { useSelector } from 'react-redux';


export function SiteTable() {
    const headers = ["שם", "כתובת"];
    const sites = useSelector((state) => state.sites.data);
    return (
        <div className="overflow-x-auto">
            <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
                <thead className="ltr:text-left rtl:text-right">
                    <tr>
                        {headers.map((header) => {
                            return (<th key={header} className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                                {header}
                            </th>)
                        })}
                        <th className="px-4 py-2"></th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                    {sites && sites.map((site) => {
                        return <SiteRow key={site.id} site={site} />
                    })}
                </tbody>
            </table>
        </div>
    );
}
