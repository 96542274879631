import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import * as api from "../../services/employee.api";

const initialState = {
    employee:{},
    data: [],
    employeesSites:[],
    isLoading:false
}
export const employeesSlice = createSlice({
    name: 'employees',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
            builder.addCase(createOrUpdateEmployee.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            }).addCase(createOrUpdateEmployee.pending, (state) => {
                state.isLoading = true
            }).addCase(createOrUpdateEmployee.rejected, (state) => {
                state.isLoading = false
            }).addCase(getEmployee.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.employee = payload;
            }).addCase(getEmployee.pending, (state) => {
                state.isLoading = true
            }).addCase(getEmployee.rejected, (state) => {
                state.isLoading = false
            }).addCase(getEmployeesSites.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.employeesSites = payload;
            }).addCase(getEmployeesSites.pending, (state) => {
                state.isLoading = true
            }).addCase(getEmployeesSites.rejected, (state) => {
                state.isLoading = false
            }).addCase(getEmployees.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        }).addCase(getEmployees.pending, (state) => {
            state.isLoading = true
        }).addCase(getEmployees.rejected, (state) => {
            state.isLoading = false
        })

    }
});

export const getEmployee = createAsyncThunk(
    'employee/getEmployee',
    async (id) => {
        const response = await api.getEmployeeApi(id);
        return response.data;
    }
)
export const getEmployees = createAsyncThunk(
    'employee/getEmployees',
    async () => {
        const response = await api.getEmployeesApi();
        return response.data;
    }
)

export const createOrUpdateEmployee  = createAsyncThunk(
    'employee',
    async (employee) => {
        const response = await api.createOrUpdateEmployeeApi(employee);
        return response.data;
    }
)
export const getEmployeesSites  = createAsyncThunk(
    'employee/getEmployeesSite',
    async (id) => {
        const response = await api.getEmployeesSites(id);
        return response.data;
    }
)

// Action creators are generated for each case reducer function
export const {} = employeesSlice.actions

export default employeesSlice.reducer
