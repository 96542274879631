import {axiosInstance} from '../services/commonConfiguration';


export async function getEmployeeApi(id) {
    return await axiosInstance.get('/employee/'+id);
}
export async function getEmployeesApi() {
    return await axiosInstance.get('/employee');
}
export async function createOrUpdateEmployeeApi(employee) {
    return await axiosInstance.post('/employee', employee);
}

export async function getEmployee(id) {
    return await axiosInstance.get('/employee/'+id);
}

export async function getEmployeesSites(id) {
    return await axiosInstance.get('/employee/'+id+'/employeesSites');
}
