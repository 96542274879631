import axios from 'axios'

const customHeaders = {
    'Constructor': '1',
};
export const axiosInstance = axios.create({
    // baseURL: 'https://localhost:7206', // Replace with your API URL
    baseURL: 'https://construct-hub.online', // Replace with your API URL
    headers: customHeaders
});


axiosInstance.interceptors.request.use(
  (config) => {
    const bearerToken = getCookie("access_token");
    if(bearerToken){
      config.headers['Authorization'] = bearerToken;
    }
    const constructor = getCookie("id");
    if(constructor){
      config.headers['Constructor'] = constructor;
    }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
);
axiosInstance.interceptors.response.use(
  (response) => {
    if(response.headers['authorization']){
      window.location.replace("/");
    }
      return response;
    },
    (error) => {
      if(error.response && error.response.status === 401){
        window.location.replace("/login");
      }
      return Promise.reject(error);
    }
);

export function setCookie(name, value, daysToExpire) {
const expirationDate = new Date();
expirationDate.setDate(expirationDate.getDate() + daysToExpire);

const cookieString = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
document.cookie = cookieString;
}

export function getCookie(name) {
const cookies = document.cookie.split(';');
for (let i = 0; i < cookies.length; i++) {
  const cookie = cookies[i].trim();
  if (cookie.startsWith(name + '=')) {
      return cookie.substring(name.length + 1);
  }
}
return null;
}

export function deleteCookie(cookieName) {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}