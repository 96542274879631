export const contactDefaults = {
    "title": "",
    "firstName": "",
    "lastName": "",
    "email": "",
    "phoneNumber": "",
    "city": "",
    "address": ""
}
export const expenseDefaults = {
    "title": "",
    "details": "",
    "amount": "",
    "paymentType": "",
    "paidTo": "",
    "receiptNumber": ""
}
export const newSiteDefaults = {
    "name": "",
    "type": {
        "typeName": ""
    },
    "city": "",
    "address": "",
    "contacts": [contactDefaults],
    "budget": 0,
    "totalPayed": 0,
};
export const newSitePayment = {
    "amount": 0,
    "paymentType": "",
    "actualDayOfPayment": undefined,
    "from": "",
    "details": "",
    "receiptNumber": "",
};
export const currencyFormatter = {
    style: 'currency',
    currency: 'ILS', // Israeli New Shekel currency code
    minimumFractionDigits: 0, // Minimum number of decimal places
    maximumFractionDigits: 0, // Maximum number of decimal places
};

export const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based, so add 1
    const year = date.getFullYear();

    // Use template literals to format the date
    return `${day}/${month}/${year}`;
};
