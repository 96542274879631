import React, {useEffect} from 'react';
import '../../globals.css'
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {getPaymentStepsGroup, getPaymentStepsTemplate, getSite} from "./sites.slice";
import SiteHeaderSection from "../Sections/SiteHeaderSection";
import SiteDetailsSection from "../Sections/SiteDetailsSection";
import PaymentsSection from "../Sections/PaymentsSection";
import ContactSection from "../Sections/ContactSection";
import SectionWrapper from "../SectionWrapper";
import ExpensesSection from "../Sections/ExpensesSection";
import DeleteButtonSection from '../Sections/DeleteButtonSection';
import TermsOfPayment from '../Sections/TermsOfPayment';
import EmployeesSection from '../Sections/EmployeesSection';


export function SiteView() {

    const {id} = useParams();
    useEffect(() => {
        dispatch(getSite(id));
        dispatch(getPaymentStepsGroup(id));
        dispatch(getPaymentStepsTemplate());
    }, [id]);
    const dispatch = useDispatch();
    const isloading = useSelector((state) => state.sites.isLoading);
    const site = useSelector((state) => state.sites.site);
    const nav = useNavigate();
    const deleteSite = async()=>{
        //delete site
        nav("/");
    }
    //todo: create sections for the "SiteView" component:
    //              Site information section,
    //              Contacts section,
    //              Budget section (Budget, paid, etc.)
    //              Employees section,
    //              Site steps section,
    //              Schedules section
    // add save site button using await dispatch(createOrUpdateSite(newSite));
    return (
        <section className='my-14'>
            {isloading && <div>loading</div>}
            {(!isloading && site) && <section>
                <div className='flex justify-end my-2 mx-4'>
                <button onClick={()=>nav(-1)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
</svg>

                </button>
                </div>
               <SiteHeaderSection site={site}/>
                {/* <SectionWrapper title={"פרטים"}>
               <SiteDetailsSection site={site}/>
                </SectionWrapper> */}
                <SectionWrapper title={"אנשי קשר"}>
                    <ContactSection contacts={site.contacts}/>
                </SectionWrapper>
                <SectionWrapper title={"עובדים"}>
                    <EmployeesSection employees={site.employees}/>
                </SectionWrapper>
                <SectionWrapper title={"תנאי תשלום"}>
               <TermsOfPayment />
                </SectionWrapper>
                <SectionWrapper title={"תשלומים"}>
               <PaymentsSection payments={site.payments}/>
                </SectionWrapper>
                <SectionWrapper title={"הוצאות"}>
                    <ExpensesSection expenses={site.expenses}/>
                </SectionWrapper>

               {/* <DeleteButtonSection massege={"אתה בטוח שאתה רוצה למחוק את האתר?"} onConfirm={deleteSite}/> */}
            </section>
            }
            {(!isloading && !site) && <div>Not Found</div>}
        </section>
    );
}
