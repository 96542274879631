import { createSlice } from '@reduxjs/toolkit'
import { registerUser, loginUser, logoutUser, getUser } from './auth.actions'
import { deleteCookie, setCookie } from '../../services/commonConfiguration'


const initialState = {
    loading: false,
    userInfo: {}, // for user object
    userToken: null, // for storing the JWT
    error: null,
    success: false, // for monitoring the registration process.
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: {
        // register user
        [registerUser.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [registerUser.fulfilled]: (state, action) => {
            state.userInfo = action.payload.user
            state.userToken = action.payload.token
            state.success = true // registration successful
            state.loading = false
            setCookie("id",action.payload.user.id,1);
            setCookie("access_token",action.payload.token,1);
                },
        [registerUser.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
            if(state.error.status === 409){
                alert("משתמש זה קיים במערכת");
            }
        },
        // login user
        [loginUser.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [loginUser.fulfilled]: (state, action) => {
            state.userInfo = action.payload.user
            state.userToken = action.payload.token
            state.success = true // registration successful
            state.loading = false
            setCookie("id",action.payload.user.id,1);
            setCookie("access_token",action.payload.token,1);
        },
        [loginUser.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
            if(state.error.status === 401){
                alert("לא קיים משתמש");
            }
        },
        // logout user
        [logoutUser.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [logoutUser.fulfilled]: (state) => {
            state.userInfo = {}
            state.userToken =null
            state.success = true // registration successful
            state.loading = false
            deleteCookie("id");
            deleteCookie("access_token");
        },
        [logoutUser.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        // get user
        [getUser.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getUser.fulfilled]: (state,{payload}) => {
            state.userInfo = payload
            state.success = true // registration successful
            state.loading = false
        },
        [getUser.rejected]: (state) => {
            state.loading = false
            state.error = "failed to get user"
        },
    },
})


export default authSlice.reducer
