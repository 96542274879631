import React, { useEffect, useState } from 'react'
import Popup from "../Common/Popup";
import { useForm } from 'react-hook-form';
import TermsOfPaymentFormRow from './TermsOfPaymentFormRow';
import {createPaymentStepsTemplate} from '../Site/sites.slice';
import TermsOfPaymentEditTemplateRow from './TermsOfPaymentEditTemplateRow';
import { useDispatch, useSelector } from 'react-redux';


export default function TermOfPaymentForm({setIsOpen,template = null}) {
    const { handleSubmit, register, reset } = useForm();
    const siteId = useSelector(state=> state.sites.site.id);
    const [termsOfPayment,setTermsOfPayment] = useState(template && template.steps ? [...template.steps] : []);
    const [sortedSteps,setSortedSteps] = useState([]);
    const [templateName,setTemplateName] = useState(template ? template.templateName : "");
    const [templateBudget,setTemplateBudget] = useState(template ? template.templateBudget : "");
    const dispatch = useDispatch();

    useEffect(()=>{
        const fiteredSteps = termsOfPayment.sort((a, b) => {
            return a.order - b.order;
        });
        setSortedSteps(fiteredSteps);
        const newBudget = termsOfPayment.reduce((sum,item)=>sum+ parseFloat(item.price), 0);
        console.log(newBudget);
        if(templateBudget == "" || templateBudget<newBudget){
            debugger;
            setTemplateBudget(newBudget);
        }
    },[termsOfPayment])


    const onCreateNewTable = ()=>{
        if(termsOfPayment.length>0 && templateName!="" && templateBudget!=""){
            const newPaymentTemplate = {
                templateName,
                templateBudget,
                siteId,
                steps: [...termsOfPayment]
            }
            console.log(newPaymentTemplate);
            dispatch(createPaymentStepsTemplate(newPaymentTemplate));
            reset();
            setIsOpen(false);
        }else{
            alert("יש להזין את פרטי הפרוייקט: שם, תקציב ושלבי עבודה")
        }
    }

const handleCreateTermsPayment=(data)=>{
    data.id = Date.now();
    console.log(data);
    setTermsOfPayment([...termsOfPayment,data]);
    reset();
}

const deleteRow = (step)=>{
    const tempTerms = termsOfPayment.filter(item => {
        for (const prop in step) {
          if (item[prop] !== step[prop]) {
            return true;
          }
        }
        return false;
      });
setTermsOfPayment(tempTerms);
}



  return (
      <Popup title={"הוספת תשלום"} cancelButtonTitle={"ביטול"} saveButtonTitle={"שמירה"}
                       onCancel={() => setIsOpen(false)} >
                        <>
                        <label className='flex justify-start mt-3'>שם הפרוייקט:</label>
                        <input onChange={(e)=> setTemplateName(e.target.value)} defaultValue={templateName} className="w-1/2 block rounded-lg border-gray-200 p-3 text-sm border" type="text"/>
                        <label className='flex justify-start mt-3'>תקציב:</label>
                        <input  onChange={(e)=> setTemplateBudget(e.target.value)} value={templateBudget} className="w-1/2 block rounded-lg border-gray-200 p-3 text-sm border" type="text"/>
                        </>
                    <form onSubmit={handleSubmit(handleCreateTermsPayment)}>
                        <table className='table table-fixed border-collapse text-center'>
                            <thead>
                                <tr>
                            <th className='w-20'>#</th>
                            <th>תיאור*</th>
                            <th className='w-30'>מחיר</th>
                            <th className='w-20'>%</th>
                            <th className='w-2'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {termsOfPayment.length>0 && sortedSteps.map((item,i)=>{
                                    return(
                                        <TermsOfPaymentEditTemplateRow key={i} item={item} register={register} templateBudget={templateBudget} deleteRow={deleteRow}/>
                                    )
                                })}
                            <TermsOfPaymentFormRow register={register}/>

                            </tbody>
                        </table>
                    </form>

                        <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 flex justify-center mt-4">
                    <button onClick={onCreateNewTable}
                        type="button"
                        className="me-2 inline-block rounded border border-indigo-600 px-12 py-3 text-sm font-medium text-indigo-600 hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring active:bg-indigo-500"
                    >
                         שמירה
                    </button>
                    <button
                        type="button"
                        className="ms-2 inline-block rounded border border-indigo-600 px-12 py-3 text-sm font-medium text-indigo-600 hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring active:bg-indigo-500"
                        onClick={() => setIsOpen(false)}
                    >
                        ביטול
                    </button>
                    </div>
                </Popup>
                )
}
