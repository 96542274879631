import { Home } from "./components/Home";
import Login from "./components/Auth/Login";
import { CreateSiteForm } from "./components/Site/CreateSiteForm";
import {SiteView} from "./components/Site/SiteView";
import Employees from "./components/Employees/Employees";
import Register from "./components/Auth/Register";
import NewEmployeeForm from "./components/Employees/NewEmployeeForm";
import EmployeeView from "./components/Employees/EmployeeView";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/sites/newSite',
    element: <CreateSiteForm />
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/register',
    element: <Register />
  },
  {
    path: '/sites/:id',
    element: <SiteView/>
  },
  {
    path: '/employees',
    element: <Employees/>
  },
  {
    path: '/employees/add',
    element: <NewEmployeeForm/>
  },
  {
    path: '/employees/:id',
    element: <EmployeeView/>
  }
];

export default AppRoutes;
