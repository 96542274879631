import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getApplicationData,createOrUpdateSite } from '../Site/sites.slice';
import TermOfPaymentForm from './TermOfPaymentForm';
import TermsOfPaymentTable from './TermsOfPaymentTable';
import { useParams } from 'react-router-dom';

export default function TermsOfPayment() {
    const {id} = useParams();
    const [isOpen,setIsOpen] = useState(false);
    const termsOfPayments = useSelector(state => state.sites.paymentStepsGroup);
    const templates = useSelector(state => state.sites.paymentsTemplate);
    const site = useSelector(state => state.sites.site);
    const dispatch = useDispatch();
    const [template,setTemplate] = useState(null);
    const applicationData = useSelector((state) => state.sites.applicationData);
    useEffect(()=>{
        if (!applicationData){
            dispatch(getApplicationData());
        }
    },[]);

    useEffect(()=>{
        if(termsOfPayments.length >0 && termsOfPayments[0].siteId == id){
            calcBudget();
        }
    },[termsOfPayments])

    const calcBudget = ()=>{
        const budget = termsOfPayments.reduce((sum, item) => sum + item.templateBudget, 0);
        console.log(budget);
        if(budget != site.budget){
            const updatedSite = {...site,budget}
            dispatch(createOrUpdateSite(updatedSite))
        }
    }

    const showForm = (_template)=>{
        setTemplate(_template);
        setIsOpen(true);
    }

    const renderTypes = () => {
        if (templates){
            return templates.map((template,i) => (
                <button key={i} onClick={()=>showForm(template)} className='border border-spacing-1 mr-2 p-3 rounded-lg hover:bg-slate-200'>{template.templateName}</button>
                ));
        }
    }

    return (
    <div>
        {termsOfPayments.map((item,i)=>{
            return(
                <TermsOfPaymentTable key={i} paymentStepsGroup={item}/>
            )
        })}
        {/* {renderTypes()} */}
        <button onClick={()=>showForm(null)} className='border border-spacing-1 mr-2 p-3 rounded-lg hover:bg-slate-200'>פורמט חדש</button>
        {isOpen && <div className="rounded-lg border border-gray-100 text-center shadow-xl">
                <TermOfPaymentForm setIsOpen={setIsOpen} template={template}/>
                </div>}

    </div>
  )
}
