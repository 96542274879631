import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import * as api from "../../services/site.api";
import {contactDefaults, expenseDefaults, newSiteDefaults, newSitePayment} from "./site.utils";

const initialState = {
    showCreateSiteForm: false,
    site: undefined,
    newSiteDefaults: newSiteDefaults,
    newContactDefaults: contactDefaults,
    newExpenseDefaults: expenseDefaults,
    payment: newSitePayment,
    data: [],
    paymentStepsGroup:[],
    paymentsTemplate:[],
    applicationData: undefined,
    isLoading: false,
    errorMessage: undefined,
}
export const sitesSlice = createSlice({
    name: 'sites',
    initialState,
    reducers: {
        showCreateSiteFormAction: (state) => {
            state.showCreateSiteForm = !state.showCreateSiteForm
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSites.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        }).addCase(getSites.pending, (state) => {
            state.isLoading = true
        }).addCase(getSites.rejected, (state) => {
            state.isLoading = false
        }).addCase(createOrUpdateSite.fulfilled, (state, {payload}) => {
            // state.isLoading = false;
            state.data = payload;
        }).addCase(createOrUpdateSite.pending, (state) => {
            // state.isLoading = true
        }).addCase(createOrUpdateSite.rejected, (state) => {
            // state.isLoading = false
        }).addCase(getApplicationData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.applicationData = action.payload;
        }).addCase(getApplicationData.pending, (state) => {
            state.isLoading = true
        }).addCase(getApplicationData.rejected, (state) => {
            state.isLoading = false
        }).addCase(getPaymentStepsGroup.fulfilled, (state, action) => {
            state.isLoading = false;
            const tempPatmentsSteps = action.payload;
            const filterPaymentStepsGroup = tempPatmentsSteps.filter(item=> state.site.id!=null && item.siteId ==state.site.id);
            state.paymentStepsGroup = filterPaymentStepsGroup;
        }).addCase(getPaymentStepsGroup.pending, (state) => {
            state.isLoading = true
        }).addCase(getPaymentStepsGroup.rejected, (state) => {
            state.isLoading = false
        }).addCase(getPaymentStepsTemplate.fulfilled, (state, action) => {
            state.isLoading = false;
            state.paymentsTemplate = action.payload;
        }).addCase(getPaymentStepsTemplate.pending, (state) => {
            state.isLoading = true
        }).addCase(getPaymentStepsTemplate.rejected, (state) => {
            state.isLoading = false
        }).addCase(getSite.fulfilled, (state, action) => {
            state.site = action.payload;
        }).addCase(getSite.pending, (state) => {
            //pending function
        }).addCase(getSite.rejected, (state) => {
            state.site = newSiteDefaults;
        }).addCase(addPayment.fulfilled, (state, action) => {
            state.site = action.payload;
        }).addCase(addPayment.pending, (state) => {
            // pending function
        }).addCase(addPayment.rejected, (state) => {
            state.errorMessage = "Failed to add payment";
        }).addCase(addContact.fulfilled, (state, action) => {
            state.site.contacts = action.payload;
        }).addCase(addContact.pending, (state) => {
            // pending function
        }).addCase(addContact.rejected, (state) => {
            state.errorMessage = "Failed to add contact";
        }).addCase(addExpense.fulfilled, (state, action) => {
            state.site.expenses = action.payload;
        }).addCase(addExpense.pending, (state) => {
            // pending function
        }).addCase(addExpense.rejected, (state) => {
            state.errorMessage = "Failed to add expense";
        }).addCase(createPaymentStepsTemplate.fulfilled, (state, action) => {
            const tempPatmentsSteps = action.payload;
            const filterPaymentStepsGroup = tempPatmentsSteps.filter(item=> state.site.id!=null && item.siteId ==state.site.id);
            state.paymentStepsGroup = filterPaymentStepsGroup;
            }).addCase(createPaymentStepsTemplate.pending, (state) => {
            // pending function
        }).addCase(createPaymentStepsTemplate.rejected, (state) => {
            state.errorMessage = "Failed to add payment template";
        }).addCase(editPaymentStepsTemplate.fulfilled, (state, action) => {
            const tempPatmentsSteps = action.payload;
            const filterPaymentStepsGroup = tempPatmentsSteps.filter(item=> state.site.id!=null && item.siteId ==state.site.id);
            state.paymentStepsGroup = filterPaymentStepsGroup;
            }).addCase(editPaymentStepsTemplate.pending, (state) => {
            // pending function
        }).addCase(editPaymentStepsTemplate.rejected, (state) => {
            state.errorMessage = "Failed to edit payment template";
        }).addCase(addSiteEmployee.fulfilled, (state, {payload}) => {
            state.site.employees = payload;
            }).addCase(addSiteEmployee.pending, (state) => {
// pending function
            }).addCase(addSiteEmployee.rejected, (state) => {
            state.errorMessage = "Failed to associate employee";
        })
    }
});

export const getSite = createAsyncThunk(
    'sites/getSite',
    async (id) => {
        const response = await api.getSiteApi(id);
        return response.data;
    }
)
export const getSites = createAsyncThunk(
    'sites/getSites',
    async () => {
        const response = await api.getSitesApi();
        return response.data;
    }
)
export const getApplicationData = createAsyncThunk(
    'application/getData',
    async () => {
        const response = await api.getApplicationDataApi();
        return response.data;
    }
)
export const getPaymentStepsGroup = createAsyncThunk(
    'PaymentStepsGroup/getData',
    async (siteId) => {
        const response = await api.getPaymentStepsGroup(siteId);
        return response.data;
    }
)
export const getPaymentStepsTemplate = createAsyncThunk(
    'PaymentStepsTemplate/getData',
    async () => {
        const response = await api.getPaymentStepsTemplate();
        return response.data;
    }
)
export const createPaymentStepsTemplate = createAsyncThunk(
    'PaymentStepsTemplate/create',
    async (paymentsTemplate) => {
        const response = await api.createPaymentStepsTemplate(paymentsTemplate);
        return response.data;
    }
)
export const editPaymentStepsTemplate = createAsyncThunk(
    'PaymentStepsTemplate/edit',
    async (paymentsTemplate) => {
        const response = await api.editPaymentStepsTemplate(paymentsTemplate);
        return response.data;
    }
)
export const createOrUpdateSite  = createAsyncThunk(
    'sites/createOrUpdateSite',
    async (site,{dispatch}) => {
        debugger;
        const response = await api.createOrUpdateSitesApi(site);
        dispatch(getSites());
        return response.data;
    }
)

export const addPayment  = createAsyncThunk(
    'sites/addPayment',
    async (payment) => {
        const response = await api.addPaymentApi(payment);
        return response.data;
    }
)

export const addSiteEmployee  = createAsyncThunk(
    'sites/addSiteEmployee',
    async ({employeeId,siteId}) => {
        const response = await api.addSiteEmployeeApi(employeeId,siteId);
        return response.data;
    }
)

export const addContact  = createAsyncThunk(
    'sites/addContact',
    async (contact) => {
        const response = await api.addContactApi(contact);
        return response.data;
    }
)
export const addExpense  = createAsyncThunk(
    'sites/addExpense',
    async (expense) => {
        const response = await api.addExpenseApi(expense);
        return response.data;
    }
)

// Action creators are generated for each case reducer function
export const {showCreateSiteFormAction} = sitesSlice.actions

export default sitesSlice.reducer
