import {axiosInstance} from '../services/commonConfiguration';


export async function getSitesApi() {
    return await axiosInstance.get('/site')
}

export async function getSiteApi(id) {
    return await axiosInstance.get('/site/' + id)
}

export async function getApplicationDataApi() {
    return await axiosInstance.get('/application')
}
export async function getPaymentStepsGroup(siteId) {
    return await axiosInstance.get('/site/PaymentStepsGroupTemplate?siteId='+siteId)
}
export async function getPaymentStepsTemplate() {
    return await axiosInstance.get('/site/PaymentStepsGroupTemplate')
}
export async function createPaymentStepsTemplate(paymentsTemplate) {
    return await axiosInstance.post('/site/PaymentStepsGroupTemplate',paymentsTemplate)
}
export async function editPaymentStepsTemplate(paymentsTemplate) {
    return await axiosInstance.put('/site/PaymentStepsGroupTemplate',paymentsTemplate)
}

export async function createOrUpdateSitesApi(site) {
    return await axiosInstance.post('/site', site);
}

export async function addPaymentApi(payment) {
    return await axiosInstance.post('/site/payment', payment);
}
export async function addSiteEmployeeApi(employeeId,siteId) {
    return await axiosInstance.post('/site/employees', {employeeId,siteId});
}

export async function addContactApi(contact) {
    return await axiosInstance.post('/site/contacts', [contact]);
}

export async function addExpenseApi(expense) {
    return await axiosInstance.post('/site/expense', expense);
}
