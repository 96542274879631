import { configureStore } from '@reduxjs/toolkit';
import sitesReducer from '../components/Site/sites.slice';
import authReducer from '../components/Auth/auth.slice';
import employeesReducer from '../components/Employees/employees.slice';
import { setupListeners } from '@reduxjs/toolkit/query'


export const store = configureStore({
    reducer: {
        sites: sitesReducer,
        auth: authReducer,
        employees: employeesReducer
    }
})

setupListeners(store.dispatch)
