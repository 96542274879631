import { createAsyncThunk } from '@reduxjs/toolkit'
import * as api from "../../services/auth.api";
export const registerUser = createAsyncThunk(
    'auth/register',
    async ({ firstName, lastName, email, password }, { rejectWithValue }) => {
        try {
            const response = await api.registerUserApi({ firstName, lastName, email, password });
            return {user: response.data, token: response.headers['authorization']};
        } catch (error) {
                return rejectWithValue(error.response);
        }
    }
)
export const loginUser = createAsyncThunk(
    'auth/login',
    async ({ email, password }, { rejectWithValue }) => {
        try {
            const response = await api.loginUserApi({ email, password })
            return {user: response.data, token: response.headers['authorization']};
        } catch (error) {
            if(error.response){
                return rejectWithValue(error.response);
            }
        }
    }
)
export const logoutUser = createAsyncThunk(
    'auth/logout',
    async () => {
        try {
                await api.logoutUserApi();
        } catch (error) {
            console.log(error);
        }
    }
)
export const getUser = createAsyncThunk(
    'auth/getUser',
    async () => {
                const response= await api.getUserApi();
                return response.data;
    })
