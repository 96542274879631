import React from 'react'
import BudgetSection from "./BudgetSection";

export default function SiteHeaderSection({site}) {
    return (
        <header>
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
                <div className="sm:flex sm:items-center sm:justify-between">
                    <div className="text-center sm:text-left">
                        <h1 className="text-2xl font-bold text-gray-900 sm:text-3xl">
                            {site.name}
                        </h1>

                        <p className="mt-1.5 text-sm text-gray-500">
                            {site.address}, {site.city}
                        </p>
                    </div>

                    <div className="mt-4 flex flex-col gap-4 sm:mt-0 sm:flex-row sm:items-center">
                        <BudgetSection budget={site.budget} paid={site.totalPayed} expenses={site.expenses}/>
                    </div>
                </div>
            </div>
        </header>
    )
}
