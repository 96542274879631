import React, {useState} from 'react';
import '../../globals.css'
import {useForm} from 'react-hook-form'
import Popup from "../Common/Popup";
import {useDispatch, useSelector} from "react-redux";
import {addExpense} from "../Site/sites.slice";
import SingleExpense from "./SingleExpense";
import InputWithLabel from "../Common/InputWithLabel";

export default function ExpensesSection({expenses}) {
    const {register, handleSubmit,reset} = useForm()
    const [isOpen, setIsOpen] = useState(false);
    const site = useSelector((state) => state.sites.site);
    const dispatch = useDispatch();
    const handleAddExpenses = async (data) => {
        data.siteId= site.id;
        data.paymentDate= undefined;
        await dispatch(addExpense(data));
        reset();
        setIsOpen(false);
    };
    const renderExpenses = () => {
        if (expenses) {
            return expenses.map((expense) => (<SingleExpense key={expense.id} expense={expense}></SingleExpense>));
        }
    }
    return (
        <section>
            <div>
                {renderExpenses()}
            </div>
            <div>
                <a
                    className="float-left mt-3 mb-3 inline-block rounded border border-indigo-600 px-12 py-3 text-sm font-medium text-indigo-600 hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring active:bg-indigo-500"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    הוספת הוצאה
                </a>
                {isOpen && <div className="rounded-lg border border-gray-100 text-center shadow-xl">
                    <Popup title={"הוספת הוצאה"} cancelButtonTitle={"ביטול"} onCancel={() => setIsOpen(false)}>
                        <form onSubmit={handleSubmit(handleAddExpenses)}>
                            <div className="space-y-12">
                                <div className="mt-6 grid grid-cols-1 gap-x-10 gap-y-10 sm:grid-cols-6">
                                    <div className="sm:col-span-12">
                                        <InputWithLabel type={"text"} id={"paidTo"} title={"לכבוד: "}
                                                        register={register}/>
                                    </div>
                                </div>
                                <div className="mt-6 grid grid-cols-1 gap-x-10 gap-y-10 sm:grid-cols-6">
                                    <div className="sm:col-span-12">
                                        <InputWithLabel type={"text"} id={"title"} title={"עבור: "}
                                                        register={register}/>
                                    </div>
                                </div>
                                <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div className="sm:col-span-3">
                                        <InputWithLabel id={"amount"} type={"text"}
                                                        title={"סכום לתשלום (לא כולל מע״מ):"} register={register}/>
                                    </div>
                                    <div className="sm:col-span-3">
                                        <InputWithLabel id={"amount-with-tax"} readOnly type={"text"}
                                                        title={"סכום לתשלום (כולל מע״מ):"} value={1}/>
                                    </div>
                                    <div className="sm:col-span-3">
                                        <InputWithLabel id={"paymentType"} type={"text"} title={"סוג תשלום:"}
                                                        register={register}/>
                                    </div>
                                    <div className="sm:col-span-3">
                                        <InputWithLabel id={"paymentDate"} type={"text"}
                                                        title={"מועד התשלום (אופציונלי):"} register={register}/>
                                    </div>
                                </div>
                                <div className="mt-4 grid grid-cols-1 gap-x-10 gap-y-10 sm:grid-cols-6">
                                    <div className="sm:col-span-12">
                                        <InputWithLabel id={"receiptNumber"} type={"text"} title={"מספר חשבונית/קבלה:"}
                                                        register={register}/>
                                    </div>
                                </div>
                                <div className="mt-4 col-span-full">
                                    <InputWithLabel id={"details"} type={"textarea"} title={"פרטים נוספים:"}
                                                    register={register}/>
                                </div>
                            </div>
                            <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 flex justify-center mt-4">
                    <button
                        type="submit"
                        className="me-2 inline-block rounded border border-indigo-600 px-12 py-3 text-sm font-medium text-indigo-600 hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring active:bg-indigo-500"
                    >
                         שמירה
                    </button>
                    <button
                        type="button"
                        className="ms-2 inline-block rounded border border-indigo-600 px-12 py-3 text-sm font-medium text-indigo-600 hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring active:bg-indigo-500"
                        onClick={() => setIsOpen(false)}
                    >
                        ביטול
                    </button>
                </div>
                        </form>
                    </Popup>
                </div>}
            </div>
        </section>
    );
}
