import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getEmployeesSites } from '../employees.slice';
import { useNavigate } from 'react-router-dom';


export default function EmployeesSitesSection({employee}) {
const dispatch = useDispatch();
const employeesSites = useSelector(state=>state.employees.employeesSites);
const nav = useNavigate();

useEffect(()=>{
  if(employee.id != null){
    dispatch(getEmployeesSites(employee.id));
  }
},[employee.id]);

  return (
    <>
    {employeesSites.length == 0 ? <h2 className='text-center'>אין אתרים משוייכים</h2> :
    <table className='table table-auto border-collapse border'>
      <thead>
        <tr>
          <th>שם האתר</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
    {employeesSites.length > 0 && employeesSites.map((site,i)=>{
      return(
          <tr key={i}>
            <td>{site.name}</td>
            <td><button onClick={()=>{
            nav("/sites/"+site.id);
            }} className='border p-2 rounded-xl hover:bg-slate-200'>צפייה</button></td>
          </tr>
      )
    })}
     </tbody>
    </table>
}
    </>
  )
}
