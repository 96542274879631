import React from 'react'

export default function EmployeeDetailsSection({employee}) {
  return (
    <section>
    <div>
    <div className='h6 font-bold'>שם:</div>
        <div className='p'>{employee.firstName} {employee.lastName}</div>
    <div className='h6 font-bold mt-3'>כתובת:</div>
        <div className='p'>{employee.address}, {employee.city}</div>
    <div className='h6 font-bold mt-3'>טלפון: </div>
        <div className='p'>{employee.phoneNumber}</div>
        {employee.employee!= null && <>
    <div className='h6 font-bold mt-3'>אימייל: </div>
        <div className='p'>{employee.email}</div>
        </>}
    <div className='h6 font-bold mt-3'>סוג עבודה: </div>
        <div className='p'>{employee.employeeType}</div>
    <div className='h6 font-bold mt-3'>מחיר שעתי: </div>
        <div className='p'>{employee.unitCost}</div>
    </div>
    {/*{JSON.stringify(site)}*/}
</section>  )
}
