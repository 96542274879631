import React from 'react'
import TermsOfPaymentOnlyViewRow from './TermsOfPaymentOnlyViewRow';
import {useDispatch} from 'react-redux'
import { editPaymentStepsTemplate } from '../Site/sites.slice';
import { currencyFormatter } from '../Site/site.utils';

export default function TermsOfPaymentTable({paymentStepsGroup}) {
    const dispatch = useDispatch();

    const sortedSteps = [...paymentStepsGroup.steps].sort((a, b) => {
          return a.order - b.order;
      });

      const onSelectStatus = (item ,select)=>{
        const index = paymentStepsGroup.steps.findIndex((step) => step === item);
        if (index !== -1) {
            const newItem = {...item,status:select}
            const updatedSteps = [...paymentStepsGroup.steps];
            updatedSteps[index] = newItem;
            const updatedPaymentStepsGroup = { ...paymentStepsGroup, steps: updatedSteps };
        dispatch(editPaymentStepsTemplate(updatedPaymentStepsGroup));
      }
    }

  return (
    <>
    <h2 className='underline underline-offset-8 my-4'>{paymentStepsGroup.templateName} : {paymentStepsGroup.templateBudget.toLocaleString(undefined, currencyFormatter)}₪</h2>
    <table className='table table-auto border-collapse'>
    <thead>
        <tr>
        <th className='w-5'>#</th>
        <th>שם</th>
        <th className='w-40'>מחיר</th>
        <th className='w-40'>מחיר כולל מע״מ</th>
        <th>%</th>
        <th className='w-44'>סטטוס</th>
        </tr>
    </thead>
    <tbody>
        {sortedSteps.map((step,i)=>{
            return(
                <TermsOfPaymentOnlyViewRow key={i} item={step} paymentStepsGroup={paymentStepsGroup} onSelectStatus={onSelectStatus}/>
            )
        })}
    </tbody>
    </table>
    </>
  )
}
