import React from 'react'
import EmployeeRow from './EmployeeRow';

export default function EmployeesTable({employees}) {
    const headers = ["שם פרטי","שם משפחה","סוג"];
    return (
        <div className="overflow-x-auto w-4/5 mx-auto my-10">
            <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
                <thead className="ltr:text-left rtl:text-right">
                    <tr>
                        {headers.map((header) => {
                            return (<th key={header} className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                                {header}
                            </th>)
                        })}
                        <th className="px-4 py-2"></th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                    {Array.isArray(employees) && employees.map((employee) => {
                        return <EmployeeRow key={employee.id} employee={employee} />
                    })}
                </tbody>
            </table>
        </div>
    );
}
