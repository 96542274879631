import { Transition } from '@headlessui/react'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from './auth.actions';

export default function UserPopup({isOpen,handleLogout}) {
    const user = useSelector(state=> state.auth.userInfo);
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(getUser())
    },[]);

  return (
    <Transition
    show={isOpen}
    enter="transition-opacity duration-300"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="transition-opacity duration-300"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    {/* Content to be animated */}
    <div className="fixed left-5 w-1/4 h-1/3 flex justify-center border rounded-lg">
    <div className="p-4 bg-white w-full rounded-lg text-center">
      <p>שלום {user.firstName} {user.lastName}</p>
    <button onClick={handleLogout} className='underline underline-offset-4'>logout</button>
    </div>
  </div>
  </Transition>
    )}
