import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SiteGridItem from './SiteGridItem';

export default function SitesGrid() {
    const sites = useSelector(state => state.sites.data);
    const nav = useNavigate();

    return (
        <div>
            <div id={"site-list"} className="grid grid-cols-2 lg:grid-cols-3 gap-4">
                {sites.length >0 && sites.map(item => {
                    return (
                        <SiteGridItem key={item.id} item={item}/>
                    )
                })}
            </div>
        </div>
    )
}
