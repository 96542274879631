import React, {Fragment} from 'react';

export default function InputWithLabel(props) {

    const renderInput = () => {
        const commonProps = {
            ...props,
            ...(props.register && props.register(props.id)),
            className: "block rounded-md border border-1 py-1.5 text-gray-900 sm:text-sm sm:leading-6"
        };
        switch (props.type) {
            case 'textarea':
                return <textarea {...commonProps} />;
            default:
                return <input {...commonProps} />;
        }
    }
    return (
        <>
            <label htmlFor={props.id}
                   className="float-end block text-sm font-medium leading-6 text-gray-900">
                {props.title}
            </label>
            <div className="mt-2">
                {renderInput()}
            </div>
        </>
    )
}
