import React, { useEffect, useState } from 'react'
import EmployeesTable from '../Employees/EmployeesTable'
import Popup from '../Common/Popup';
import { useDispatch, useSelector } from 'react-redux';
import { addSiteEmployee } from '../Site/sites.slice';
import {useParams} from 'react-router-dom';
import { getEmployees } from '../Employees/employees.slice';
import NewEmployeeForm from '../Employees/NewEmployeeForm'



export default function EmployeesSection({employees}) {
    const [isOpenAddNew,setIsOpenAddNew] = useState(false);
    const [addNewEmployee,setAddNewEmployee] = useState(false);
    const constructorEmployees = useSelector(state=>state.employees.data);
    const [employeeToAssociate,setEmployeeToAssociate] = useState();
    const allEmployees = useSelector(state=>state.employees.data);
    const dispatch = useDispatch();
    const {id} = useParams();

    useEffect(()=>{
        dispatch(getEmployees());
    },[])

    const associateEmployee = ()=>{
        dispatch(addSiteEmployee({employeeId:employeeToAssociate,siteId:id}))
        setIsOpenAddNew(false);
    }

    const associateNewEmployee = ()=>{
        console.log(allEmployees[allEmployees.length-1].id+1);
        const employeeId =allEmployees[allEmployees.length-1].id+1;
        dispatch(addSiteEmployee({employeeId:employeeId,siteId:id}))
        setIsOpenAddNew(false);
        setAddNewEmployee(false);
    }


  return (
    <>
    <button onClick={()=>setIsOpenAddNew(true)} className='border py-2 px-4 hover:text-white hover:bg-gray-600 rounded-3xl'>הוספת עובד</button>
    {isOpenAddNew &&
<Popup title={"הוספת עובד"} cancelButtonTitle={"ביטול"} saveButtonTitle={"שמירה"}
                        onCancel={() => setIsOpenAddNew(false)}>
{/* <AutoCompleteInput suggestions={constructorEmployees} placeholder={"בחר עובד"} setResult={setEmployeeToAssociate}/> */}
{!addNewEmployee ?
<>
<select onChange={(e)=>setEmployeeToAssociate(e.target.value)} class="ui dropdown py-3 px-5">
    <option value={""}>בחר עובד</option>
{constructorEmployees.map((suggestion, index) => (
        <option value={suggestion.id} key={index}>
          {suggestion.firstName} {suggestion.lastName} - {suggestion.employeeType}
        </option>
      ))}
</select>
<p>העובד לא ברשימה? <button onClick={()=>{
    setAddNewEmployee(true);
}}>הוספת עובד חדש</button></p>
</>:<>
<NewEmployeeForm associateNewEmployee={associateNewEmployee}/>
</>
}
<div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 flex justify-center mt-4">
                    {!addNewEmployee && <button
                    onClick = {associateEmployee}
                        type="submit"
                        className="me-2 inline-block rounded border border-indigo-600 px-12 py-3 text-sm font-medium text-indigo-600 hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring active:bg-indigo-500"
                    >
                         שמירה
                    </button>}
                    <button
                        type="button"
                        className="ms-2 inline-block rounded border border-indigo-600 px-12 py-3 text-sm font-medium text-indigo-600 hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring active:bg-indigo-500"
                        onClick={() => {
                            setIsOpenAddNew(false);
                            setAddNewEmployee(false);
                        }}
                    >
                        ביטול
                    </button>
                    </div>
</Popup>
    }
{employees == null || employees.length ==0 ? <h3 className='text-center my-4'>אין עובדים משוייכים לאתר זה</h3>:
    <EmployeesTable employees={employees}/>
}
    </>
  )
}
