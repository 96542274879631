import React from 'react';

export function ContactForm({ contact, register,setContact}) {
    return (
        <>
        <h4 className='h4 text-center'>פרטי יצירת קשר:</h4>
                <div className='mt-3'>
                    <input
                        className="w-full rounded-lg border-gray-200 p-3 text-sm border"
                        placeholder="תפקיד *"
                        type="text"
                        id="title"
                        required
                        {...register('title')}
                        onChange={(e)=>{
                            setContact({...contact, 'title':e.target.value});
                        }}
                        />
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    <div className='mt-3'>
                        <input
                            className="w-full rounded-lg border-gray-200 p-3 text-sm border"
                            placeholder="שם פרטי *"
                            type="text"
                            id="firstName"
                            required
                            {...register('firstName')}
                             onChange={(e)=>{
                            setContact({...contact, 'firstName':e.target.value});
                        }}
                        />
                    </div>
                    <div className='mt-3'>
                        <label className="sr-only" htmlFor="name">בעלים</label>
                        <input
                            className="w-full rounded-lg border-gray-200 p-3 text-sm border"
                            placeholder="שם משפחה *"
                            type="text"
                            id="lastName"
                            required
                            {...register('lastName')}
                             onChange={(e)=>{
                            setContact({...contact, 'lastName':e.target.value});
                        }}
                            />
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    <div className='mt-3'>
                        <label className="sr-only" htmlFor="phone">טלפון</label>
                        <input
                            className="w-full rounded-lg border-gray-200 p-3 text-sm border"
                            placeholder="טלפון *"
                            type="tel"
                            id="phoneNumber"
                            required
                            {...register('phoneNumber')}
                             onChange={(e)=>{
                            setContact({...contact, 'phoneNumber':e.target.value});
                        }}
                        />
                    </div>

                    <div className='mt-3'>
                        <label className="sr-only" htmlFor="email">אימייל</label>
                        <input
                            className="w-full rounded-lg border-gray-200 p-3 text-sm border"
                            placeholder="אימייל "
                            type="email"
                            id="email"
                            {...register('email')}
                             onChange={(e)=>{
                            setContact({...contact, 'email':e.target.value});
                        }}
                        />
                    </div>
                </div>
        </>
    )
}
