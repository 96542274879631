import React, { useState } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import './NavMenu.css';
import {useDispatch} from 'react-redux';
import {getCookie} from '../services/commonConfiguration'
import { logoutUser } from './Auth/auth.actions';
import UserPopup from './Auth/UserPopup';

const NavMenu = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const nav = useNavigate();
  const dispatch = useDispatch();
  const token = getCookie("access_token");

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };
  const handleLogout = ()=>{
    dispatch(logoutUser());
  }

  return (
    <header>
      <Navbar className="navbar-expand-sm navbar-toggleable-sm box-shadow bg-slate-200 mb-3" container light>
        <NavbarBrand tag={Link} to="/"><img src='/logo.png' width={75} alt='logo'/></NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} className="mr-2 bg-transparent border-none">
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-9 h-9"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        </span>
      </NavbarToggler>
        <Collapse className="d-sm-inline-flex flex-sm-row-reverse text-center" isOpen={!collapsed} navbar>
          {!token ?
          <button onClick={() => nav("/login")}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
            </svg>
          </button> :
           <button onClick={() => setIsOpenPopup((isOpenPopup)=>!isOpenPopup)}>
           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
             <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
           </svg>
         </button>
          }
          {token &&
          <ul className="navbar-nav flex-grow mx-10">
            <NavItem>
              <NavLink tag={Link} className="text-dark" to="/">אתרים</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} className="text-dark" to="/employees">עובדים</NavLink>
            </NavItem>

          </ul>
          }
        </Collapse>
      </Navbar>
      {token &&
      <UserPopup isOpen={isOpenPopup} handleLogout={handleLogout}/>
      }
    </header>
  );
};

export default NavMenu;

