import React from 'react';
import '../../globals.css'
import SectionWrapper from "../SectionWrapper";
import {UserCircleIcon} from '@heroicons/react/20/solid'

export default function SingleContact({contact}) {
    const renderContactTitle = () => {
        return (
            <div className="lg:flex lg:items-center lg:justify-between">
                <div className="min-w-0 flex-1">
                        <strong className="block font-medium text-gray-400">{contact.title}</strong>
                    <div className="mt-1 justify-between flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                        <div className="flex items-center text-sm">
                            <UserCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true"/>
                            <p className="ms-2 hidden text-right text-s sm:block">
                                <strong className="block font-medium">{contact.firstName} {contact.lastName}</strong>

                                <span className="   "> {contact.phoneNumber} </span>
                            </p>
                        </div>
                        <div className="mt-2 flex items-center text-sm text-gray-500">

                        </div>
                        <div className="mt-2 flex items-center text-sm text-gray-500 hidden sm:block">

                        </div>
                        <div className="mt-2 flex items-center text-sm text-gray-500">
                            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                              {/*  {formattedPayment}*/}
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const renderContactBody = () => {
        return (
            <div className="flow-root">
                <dl className="-my-3 divide-y divide-gray-100 text-sm">
                    <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
                        <dt className="font-medium text-gray-900">כתובת: </dt>
                        <dd className="text-gray-700 sm:col-span-3">{contact.address}, {contact.city}</dd>
                    </div>

                    <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
                        <dt className="font-medium text-gray-900">אימייל: </dt>
                        <dd className="text-gray-700 sm:col-span-3">{contact.email}</dd>
                    </div>
                </dl>
            </div>
        )
    }
    return (
        <SectionWrapper key={contact.id} title={renderContactTitle()}>
            {renderContactBody()}
        </SectionWrapper>
    );
}
