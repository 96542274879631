import React from 'react'
import { currencyFormatter } from '../Site/site.utils'

export default function TermsOfPaymentOnlyViewRow({item,paymentStepsGroup={},onSelectStatus}) {
  return (
    <tr className={ item.status === "readyToStart" ? "bg-red-100" :
    item.status === "onProcess" ? "bg-yellow-100" :
    item.status === "done" ? "bg-green-100" :
    ""}>
<td>{item.order}</td>
<td>{item.name}</td>
<td>{item.price.toLocaleString(undefined, currencyFormatter) || ((item.precentage/100) * paymentStepsGroup.templateBudget).toLocaleString(undefined, currencyFormatter)}</td>
<td>{(item.price * 1.17 || ((item.precentage/100) * paymentStepsGroup.templateBudget)*1.17)%1 != 0 ? (item.price * 1.17 || ((item.precentage/100) * paymentStepsGroup.templateBudget)*1.17).toFixed(2).toLocaleString(undefined, currencyFormatter):(item.price * 1.17 || ((item.precentage/100) * paymentStepsGroup.templateBudget)*1.17).toLocaleString(undefined, currencyFormatter)}</td>
<td>{(item.precentage || (item.price/paymentStepsGroup.templateBudget)*100)%1 != 0 ? (item.precentage || (item.price/paymentStepsGroup.templateBudget)*100).toFixed(2): (item.precentage || (item.price/paymentStepsGroup.templateBudget)*100)}</td>
<td>
  {/* add onSelect */}
<select onChange={(e)=>onSelectStatus(item,e.target.value)} className="w-4/5  border-gray-200 p-3 text-sm bg-transparent" type="select" defaultValue={item.status} >
        <option value="" >בחר סטטוס</option>
        <option value="readyToStart" >מוכן להתחיל</option>
        <option value="onProcess" >בתהליך</option>
        <option value="done" >הושלם</option>
        </select>
                </td>

    </tr>
  )}