import React, { useEffect } from 'react'
import {useNavigate} from 'react-router-dom';
import EmployeesTable from './EmployeesTable';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployees } from './employees.slice';


export default function Employees() {
    const nav = useNavigate();
    const dispatch = useDispatch();
    const employees = useSelector((state) => state.employees.data);


    useEffect(()=>{
      dispatch(getEmployees());
    },[])
  return (
    <div>
        <button onClick={()=>{
            nav("add");
        }} className='rounded-full bg-gray-600 text-white py-2 px-3 my-6'>הוספת עובד</button>
        <EmployeesTable employees={employees}/>
    </div>
  )
}
