import React from 'react'

export default function TermsOfPaymentEditTemplateRow({item, templateBudget,deleteRow}) {
return (
<tr>
<td>{item.order}</td>
<td>{item.name}</td>
<td>{(item.price || (item.precentage/100) * templateBudget)}</td>
<td>{(item.precentage || (item.price/templateBudget)*100)%1 !=0 ? (item.precentage || (item.price/templateBudget)*100).toFixed(2):(item.precentage || (item.price/templateBudget)*100)}</td>
<td><button onClick={()=>deleteRow(item)} type='button' className='text-red-500'>X</button></td>
    </tr>
  )
}
