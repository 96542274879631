import React from 'react'

export default function EmployeeRow({employee}) {
  return (
                    <tr>
                        <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                            {employee.firstName}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                            {employee.lastName.substring(0,5)}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                            {employee.employeeType}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2">
                            <a
                                href={"/employees/"+employee.id}
                                className="inline-block rounded bg-indigo-600 px-4 py-2 text-xs font-medium text-white hover:bg-indigo-700"
                            >
                                צפייה
                            </a>
                        </td>
                    </tr>
        );
}

