import React from 'react'

export default function TermsOfPaymentFormRow({register}) {
  return (
    <tr>
    <td>
    <input {...register(`order`)} className="w-full rounded-lg border-gray-200 p-3 text-sm border" type="number" />
    </td>
    <td>
    <input {...register(`name`)} className="w-full rounded-lg border-gray-200 p-3 text-sm border" type="text" />
    </td>
    <td>
    <input {...register(`price`)} className="w-full rounded-lg border-gray-200 p-3 text-sm border" type="number" />
    </td>
    <td>
    </td>
    <td> <button type='submit' className="rounded border border-green-600 text-green-600 p-2 mt-1 hover:bg-green-600 hover:text-white focus:outline-none focus:ring active:bg-green-500">V</button></td>
            </tr>
    )
}
