import React from 'react';
import '../../globals.css'


export function SiteRow({site})  {
        return (
                    <tr>
                        <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                            {site.name}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                            {site.address}, {site.city}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2">
                            <a
                                href={"/sites/"+site.id}
                                className="inline-block rounded bg-indigo-600 px-4 py-2 text-xs font-medium text-white hover:bg-indigo-700"
                            >
                                צפייה
                            </a>
                        </td>
                    </tr>
        );
}
