import React from 'react'
import {useForm} from 'react-hook-form'
import {useDispatch} from "react-redux";
import {loginUser} from "./auth.actions";

export default function Login() {
    const {register, handleSubmit} = useForm()
    const dispatch = useDispatch();


    const handleLogin = async (data) => {
        console.log(data);
        await dispatch(loginUser(data));
    };

    return (
        <div className='mt-10'>
            <h2 className='h1 text-center'>התחברות:</h2>
            <div className='flex justify-center'>
                <form onSubmit={handleSubmit(handleLogin)} className="space-y-4 max-w-lg	" >
                    <label className="sr-only" htmlFor="email">אימייל</label>
                    <input placeholder='אימייל'
                        className="w-full rounded-lg border-gray-200 p-3 text-sm border"
                        {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })} type="email" />
                    {/*{errors.email && <div className="text-danger">* הזן אימייל תקין </div>}*/}
                    <label className="sr-only" htmlFor="password">סיסמה</label>
                    <input placeholder='סיסמה'
                        className="w-full rounded-lg border-gray-200 p-3 text-sm border"
                        {...register("password", { required: true})} type="password" />
                    {/*{errors.password && <div className="text-danger">* הזן סיסמה</div>}*/}
                    <div className='flex justify-center'>
                        <button className="inline-block w-full rounded-full bg-gray-600 px-5 py-3 font-medium text-white sm:w-auto hover:bg-gray-900">התחברות</button>
                    </div>
                </form>
            </div>
                <p className="mt-4 text-sm text-gray-500 sm:mt-0 text-center">
                                אין לך משתמש?
                                <a href="register" className="text-gray-700 underline">הירשם</a>
                            </p>
        </div>
    )
}
