import React from 'react'

export default function EmployeeHeaderSection({employee}) {
  return (
    <header>
    <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
        <div className="sm:flex sm:items-center sm:justify-between">
            <div className="text-center sm:text-left">
                <h1 className="text-2xl font-bold text-gray-900 sm:text-3xl">
                    {employee.firstName} {employee.lastName}
                </h1>

                <p className="mt-1.5 text-sm text-gray-500">
                    {employee.address}, {employee.city}
                </p>
            </div>
            <div className='border p-4 rounded-lg'>
                <p className='h5 mb-0'>עלות יומית:</p>
                <p className='text-xs text-center text-gray-600 mb-2'>8 שעות</p>
                <p className='text-center'>{employee.unitCost * 8}₪</p>
            </div>
        </div>
    </div>
</header>  )
}
