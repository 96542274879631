import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import SitesGrid from './Site/SitesGrid';
import { getSites, getApplicationData, createOrUpdateSite } from './Site/sites.slice';
import { SiteTable } from './Site/SiteTable';





export function Home() {
  const [isGrid,setIsGrid] = useState(true);
  const [isTable,setIsTable] = useState(false);
  const sites = useSelector(state=>state.sites.data);
  const onChangeView = ()=>{
    setIsGrid(!isGrid);
    setIsTable(!isTable);
  }
  const nav = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getApplicationData());
    dispatch(getSites());
  }, []);

  const createNewSite = ()=>{
    debugger;
    const name = "אתר חדש "+sites.length;
    const newSite = {
      name,
      address:"כתובת",
      city:"עיר"
    }
    dispatch(createOrUpdateSite(newSite));
  }

  return (
    <div>
      <div className='flex justify-end'>
        <button onClick={createNewSite} className='rounded-full bg-gray-600 text-white py-2 px-3'>הוספת אתר</button>
      </div>
      <div className='h3 py-3 text-center'>רשימת אתרים:</div>
      <div className='my-3'>
        <button onClick={onChangeView} className={isTable ? 'bg-gray-200 py-1 px-2.5 border-2 rounded-r-lg' : 'py-1 px-2.5 border-2 rounded-r-lg'}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 0 1-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0 1 12 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
</svg>

        </button>
        <button onClick={onChangeView} className={isGrid ? 'bg-gray-200 py-1 px-2.5 border-2 rounded-l-lg' : 'py-1 px-2.5 border-2 rounded-l-lg'}>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 0 1-1.125-1.125v-3.75ZM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 0 1-1.125-1.125v-8.25ZM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 0 1-1.125-1.125v-2.25Z" />
</svg>

        </button>
      </div>
      {isGrid && <SitesGrid />}
      {isTable && <SiteTable/>}
     
    </div>
  );
}

